var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.is_pc)?_c('div',{staticClass:"footer"},[_c('div',{staticClass:"fotter_logo"},[_vm._m(0),_c('div',{staticClass:"fotter_logo_right"},[_c('div',{staticClass:"left_ows"},[_vm._m(1),_c('div',{staticClass:"left_a"},_vm._l((_vm.ows_a_list),function(item,i){return _c('div',{key:i,staticClass:"ows_txt"},[_c('a',{staticClass:"ows_txt_a",attrs:{"title":item.title,"target":"_black","href":item.url}},[_vm._v(_vm._s(item.title))])])}),0)]),_vm._m(2)])]),_c('div',{staticClass:"beian"},[_vm._v(" © "+_vm._s(new Date().getFullYear())+" 湖南大湘人力资源集团有限公司 版权所有 "),(_vm.icp_status == 'dxrc')?_c('a',{attrs:{"href":"https://beian.miit.gov.cn/","target":"_black"}},[_vm._v(" 湘ICP备12004962号-13")]):_vm._e(),(_vm.icp_status == 'daxiang_group')?_c('a',{attrs:{"href":"https://beian.miit.gov.cn/","target":"_black"}},[_vm._v(" 湘ICP备2023037246号-1")]):_vm._e()])]):_c('div',{staticClass:"footerh5"},[_c('div',{staticClass:"fotter_logo_h5"},[_vm._m(3),_c('div',{staticClass:"fotter_logo_right_h5"},[_c('div',{staticClass:"left_ows_h5"},[_vm._m(4),_c('div',{staticClass:"left_a"},_vm._l((_vm.ows_a_list),function(item,i){return _c('div',{key:i,staticClass:"ows_txt"},[_c('a',{staticClass:"ows_txt_a",attrs:{"title":item.title,"target":"_black","href":item.url}},[_vm._v(_vm._s(item.title))])])}),0)])]),_vm._m(5)]),_c('div',{staticClass:"beian"},[_vm._v(" © "+_vm._s(new Date().getFullYear())+" 湖南大湘人力资源集团有限公司 版权所有 "),(_vm.icp_status == 'dxrc')?_c('a',{attrs:{"href":"https://beian.miit.gov.cn/","target":"_black"}},[_vm._v(" 湘ICP备12004962号-13")]):_vm._e(),(_vm.icp_status == 'daxiang_group')?_c('a',{attrs:{"href":"https://beian.miit.gov.cn/","target":"_black"}},[_vm._v(" 湘ICP备2023037246号-1")]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"logo3",attrs:{"src":require("@/assets/img/index/logo3.png"),"alt":""}}),_c('div',{staticClass:"slogn"},[_vm._v("客户为根、以人为本、专业为上、服务为先")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left_address"},[_c('div',[_vm._v(" 公司地址：湖南省长沙市天心区劳动西路348号贺龙体育场东门5040-5051房 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"gzh_img"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require("@/assets/img/index/gzh.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"logo3",attrs:{"src":require("@/assets/img/index/logo3.png"),"alt":""}}),_c('div',{staticClass:"slogn"},[_vm._v("客户为根、以人为本、专业为上、服务为先")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left_address"},[_c('div',[_vm._v(" 公司地址：湖南省长沙市天心区劳动西路348号贺龙体育场东门5040-5051房 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gzh_h5_img_div"},[_c('div',{staticClass:"gzh_img2"},[_c('img',{staticStyle:{"width":"70px","height":"70px"},attrs:{"src":require("@/assets/img/index/gzh.png"),"alt":""}})])])
}]

export { render, staticRenderFns }