<template>
  <div ref="scrollContainer" @scroll="handleScroll" class="pagemain">
    <div class="video_div">
      <Head
        v-if="is_pc"
        v-show="scrolled < 400"
        class=""
        ref="head"
        @show_change="head_change"
        :menu_index="0"
      ></Head>
      <Head @show_change="head_change" v-else class="" ref="head" :menu_index="0"></Head>

      <div v-if="is_pc">
        <div v-if="!video_error_flag">
          <video
            style="width: 100%; height: 100%"
            class="video2"
            autoplay
            muted
            playsinline
            loop
            id="mp41"
            :error="video_error"
          ></video>
        </div>
        <div v-else style="height: 50px"></div>
      </div>
      <div v-else>
        <img
          src="@/assets/img/index/index_bg1.png"
          style="width: 100%; height: 250px"
          alt=""
        />
        <div class="video_hint2" v-if="scrolled < 20 && head_hint">
          就业是民生之本 人才是第一资源
        </div>
      </div>

      <div v-if="is_pc && !video_error_flag">
        <div class="video_hint" v-if="scrolled < 20">就业是民生之本 人才是第一资源</div>
      </div>
    </div>

    <div class="ffly_div">
      <div class="news_title3">
        <div class="dito"></div>
        服务领域
      </div>
      <div class="fuwu_main">
        <div class="fuwu_main2" v-if="is_pc">
          <div v-for="(item, i) in fuwulingyu_list" :key="i" class="fuwu_div">
            <div class="fuwu_icon">
              <span
                :class="'iconfont ' + item.icon"
                :style="'font-size:' + item.icon_size + 'px;color:' + item.color"
              ></span>
            </div>
            <div class="fuwu_inner">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="fuwu_main2" v-else>
          <div v-for="(item, i) in fuwulingyu_list" :key="i" class="fuwu_div_h5">
            <div class="fuwu_icon">
              <span
                :class="'iconfont ' + item.icon"
                :style="'font-size:' + item.icon_size + 'px;color:' + item.color"
              ></span>
            </div>
            <div class="fuwu_inner2">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="yyzx_div">
      <div class="news_title3">
        <div class="dito"></div>
        运营中心
      </div>
      <div class="yyzx_tabs">
        <div style="width: 80%">
          <Carousel v-model="yunying_index" loop autoplay :autoplay-speed="4000">
            <CarouselItem v-for="(item, i) in yunying_list" :key="i">
              <div :class="is_pc?'yyzx_name':'yyzx_name_h5'">
                {{ item.title }}
              </div>
              <img
                v-if="is_pc"
                fit="contain"
                style="
                  max-width: 100%;
                  height: 400px;
                  object-fit: cover0;
                  border-radius: 10px;
                "
                :src="item.url"
                alt=""
              />
              <img
                v-else
                fit="contain"
                style="max-width: 100%; object-fit: cover0; border-radius: 10px"
                :src="item.url"
                alt=""
              />
            </CarouselItem>
          </Carousel>
        </div>
      </div>
    </div>

    <div class="menu_card1">
      <div class="news_title2">
        <div class="dito"></div>
        最新动态
      </div>
      <div class="menu_card" v-if="is_pc">
        <div
          class="news_card"
          v-for="(item, i) in news_list"
          :key="i"
          @click="click_news(item, i)"
        >
          <img :src="item.img" alt="" class="news_img" />
          <div class="news_title">
            <div class="news_date">
              {{ item.date }}
            </div>
            <p class="news_title_weight">
              {{ item.title }}
            </p>
            <div class="yuedu_yuanwen">
              <span class="ydyw"> 阅读全文 </span>
              <span>
                <Icon type="ios-arrow-forward"></Icon>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="menu_card" v-else>
        <div
          class="news_card2"
          v-for="(item, i) in news_list"
          :key="i"
          @click="click_news(item, i)"
        >
          <div class="h5_news_icon">
            <img :src="item.img" style="height: 100%; width: 100%" />
          </div>
          <div>
            <p class="news_title_weight2">
              {{ item.title }}
            </p>
          </div>

          <!-- <div class="news_title2">
            <div>
              <p class="news_title_weight2">
                {{ item.title }}
              </p>
            </div>

            <div class="news_date">
              {{ item.date }}
            </div>
          </div> -->
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import videojs from "video.js";
import { request, open_new_page, check_pc } from "../../utils/http";
import Head from "../common/head.vue";
import Footer from "../common/footer.vue";

export default {
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      is_pc: true,
      head_hint: true,
      video_error_flag: false,
      scrolled: 0,
      show_menu: true,
      video_url: "https://sl.daxiangsys.com/dx_ows/mp4/index.mp4",
      news_list: [],
      yunying_list: [
        {
          url: "https://sl.daxiangsys.com/dx_ows/png/xjxq.png",
          title: "长沙湘江新区运营中心",
        },
        {
          url: "https://sl.daxiangsys.com/dx_ows/png/sxyxzx.png",
          title: "长沙星沙运营中心",
        },
        {
          url: "https://sl.daxiangsys.com/dx_ows/png/jkq.png",
          title: "长沙经开区运营中心",
        },
        {
          url: "https://sl.daxiangsys.com/dx_ows/png/hlyyzx.png",
          title: "长沙天心区贺龙运营中心",
        },
      ],
      yunying_index: 0,

      fuwulingyu_list: [
        {
          name: "劳务派遣",
          icon: "iconlaowupaiqian",
          icon_size: 40,
          color: "#4169E1",
        },
        {
          name: "劳务外包",
          icon: "iconwaibaorenyuan",
          icon_size: 40,
          color: "#FF6100",
        },
        {
          name: "RPO",
          icon: "iconzhaopin",
          icon_size: 40,
          color: "#8A2BE2",
        },
        {
          name: "猎聘",
          icon: "iconliepin",
          icon_size: 40,
          color: "#E3170D",
        },
        {
          name: "灵活用工",
          icon: "iconlinghuoyonggong",
          icon_size: 40,
          color: "#32CD32",
        },
        {
          name: "教育培训",
          icon: "iconkaozheng",
          icon_size: 40,
          color: "#6495ED",
        },
        {
          name: "考证",
          icon: "iconkaozheng",
          icon_size: 40,
          color: "#40E0D0",
        },
        {
          name: "新媒体",
          icon: "iconxinmeitiyunying",
          icon_size: 40,
          color: "#191970",
        },
      ],
    };
  },
  mounted() {
    this.play_video();
    this.get_news_list();
    this.is_pc = check_pc();

    // window.addEventListener("scroll", this.handleScroll, true);
  },
  updated() {},
  beforeDestroy() {},

  methods: {
    get_news_list() {
      let obj = {};
      var that = this;
      request("post", process.env.VUE_APP_HOST + "/api_export/ows/news_list", obj).then(
        (res) => {
          console.log(res);
          if (res.data.code == 0) {
            console.log(res.data);
            that.news_list = res.data.data.news_list;
          } else {
            that.$Message.error(res.data.msg);
          }
        }
      );
    },

    click_news(item, i) {
      open_new_page(item.url);
    },

    handleScroll(e) {
      let scrolled = this.$refs.scrollContainer.scrollTop;
      // console.log(scrolled);
      this.scrolled = Number(scrolled);
      // if (scrolled > 200) {
      //   this.show_menu = false;
      // } else {
      //   this.show_menu = true;
      // }
      // //滚动条触发事件
      // console.log(e);
      // if (
      //   e.srcElement.scrollTop + e.srcElement.clientHeight ==
      //   e.srcElement.scrollHeight
      // ) {
      //   console.log("嘿嘿我在底部触发了");
      // }
    },
    play_video() {
      var url = this.video_url;
      const player = videojs("mp41", { autoplay: true });
      player.src(url);
      player.on("ended", () => {});
    },
    video_error(e) {
      console.log(123, e);
      this.video_error_flag = true;
    },

    head_change(e) {
      console.log(123, e);
      if (e == "open") {
        this.head_hint = false;
      } else {
        this.head_hint = true;
      }
    },
  },
};
</script>

<style scoped>
.fuwu_icon {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.fuwu_div {
  width: calc(25% - 20px);
  height: 150px;
  padding: 20px;
  background: #fff;
  margin-bottom: 20px;
  border-radius: 10px;
}
.fuwu_div_h5 {
  width: calc(50% - 10px);
  height: 110px;
  background: #fff;
  padding-top: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.fuwu_main2 {
  justify-content: space-between;
  flex-wrap: wrap;
  width: 80%;
  display: flex;
}

.fuwu_inner {
  font-size: 22px;
  color: #333;
}
.fuwu_inner2 {
  font-size: 18px;
  color: #333;
}
.fuwu_main {
  display: flex;
  justify-content: center;
}

.ffly_div {
  background: #fff7f7;
  padding: 20px 0 0 0;
}

.yyzx_div {
  background: #fff7f7;
  padding-top: 20px;
}

.yyzx_name {
  color: #1b0a68;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  font-size: 25px;
  letter-spacing: 2px;
  font-family: "yunyingzhongxin";
  text-align: center;
  margin: 10px 0 20px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #e6e6e6;
}

.yyzx_name_h5 {
  color: #1b0a68;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  font-size: 18px;
  letter-spacing: 2px;
  font-family: "yunyingzhongxin";
  text-align: center;
  margin: 10px 0 20px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #e6e6e6;
}


.yyzx_img_div {
  display: flex;
  justify-content: center;
}

.yyzx_img {
  width: 100%;
  height: 600px;
  border-radius: 20px;
  overflow: hidden;
}

.dito {
  height: 20px;
  width: 10px;
  background: #0466dd;
  border-radius: 3px;
  margin-right: 10px;
}

.news_card {
  background: #fff;
  border-radius: 5px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  overflow: hidden;
  cursor: pointer;
}

.news_card2 {
  width: 100%;
  margin: 0 10px;
  display: flex;
  justify-content: space-between;
  background: #fff;
  margin-bottom: 5px;
  border-radius: 5px;
  padding: 10px;
}
.news_card:hover {
  box-shadow: 0 0 6px #89bfd3;
}

.news_card_h5 {
  background: #fff;
  border-radius: 5px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  overflow: hidden;
  cursor: pointer;
}

.news_date {
  text-align: right;
  margin-bottom: 10px;
  color: #bbbbbb;
  font-size: 13px;
  font-weight: 300;
}

.h5_news_icon {
  width: 100px;
  height: 50px;
  flex-shrink: 0;
  margin-right: 20px;
  border-radius: 3px;
  overflow: hidden;
}

.news_title_weight {
  height: 80px;
  text-indent: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  word-break: break-all;
}
.news_title_weight2 {
  text-indent: 1em;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  word-break: break-all;
  font-size: 13px;
  display: flex;

  justify-content: flex-start;
}
.news_title {
  text-indent: 1em;
  font-size: 14px;
  width: 290px;
  height: 135px;
  text-align: left;
  font-weight: 300;
  color: #888;
  padding: 10px 10px 30px 10px;
}

.news_title2 {
  text-indent: 1em;

  font-size: 14px;
  display: flex;
}

.yyzx_tabs {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.news_img {
  width: 290px;
  height: 150px;
}

.news_img_h5 {
  width: 90px;
  height: 50px;
}

.slogn {
  font-family: "dingding02";
  font-size: 18px;
  color: #dcdcdc;
  padding-left: 20px;
}

a {
  text-decoration: none;
  color: inherit;
}

.yuedu_yuanwen {
  font-size: 12px;
  color: #ebebeb;
  text-align: right;
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

.beian {
  margin-top: 30px;
  font-size: 13px;
  color: #ccc;
  padding-top: 30px;
  border-top: 1px solid #e3e3e3;
}

.ydyw {
  position: relative;
  right: -25px;
}

.footer {
  padding: 50px 0 30px 0;
  background: #f6f6f6;
}

.card1 {
  padding: 15px 0;
  font-size: 30px;
  letter-spacing: 0.2em;
  margin: 0px 0 5px 0;
  color: #fff;
  background: #0466dd;
  font-family: "dingding01";
}

.head_menu_title {
  margin-right: 40px;
  font-size: 16px;
  color: #f3f3f3;
  font-weight: 600;
}

.video2 {
  -webkit-backface-visibility: hidden;
}
.pagemain {
  height: 100vh;
  overflow: auto;
}

.head_menu_div {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 100px;
}

.fotter_logo_right {
  display: flex;
  align-items: center;
}

.guanzhu {
  font-size: 22px;
  color: #ccc;
}

.gzh_img {
  padding: 3px;
  background: #fff;
  border-radius: 5px;
  width: 100px;
  height: 100px;
}

.fotter_logo {
  display: flex;
  padding: 0 10%;
  justify-content: space-between;
}

.menu_card1 {
  background: #efefef;
  padding-top: 40px;
  padding-bottom: 50px;
}

.menu_card {
  display: flex;
  justify-content: center;
  margin: 0 3%;
  flex-wrap: wrap;
}

.video_hint {
  color: #ffffff;
  letter-spacing: 7px;
  background: #e9e9e9ac;
  font-size: 40px;
  font-weight: 600;
  width: 100vw;
  height: 80px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 40%;
  z-index: 9999;
}

.video_hint2 {
  color: #ffffff;
  letter-spacing: 7px;
  background: #e9e9e9ac;
  font-size: 14px;
  font-weight: 600;
  width: 100vw;
  height: 40px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 120px;
  z-index: 9999;
}

.logo {
  height: 65px;
  padding: 10px;
}

.news_title2 {
  display: flex;
  align-items: center;
  padding-left: 10%;
  padding-bottom: 20px;
  justify-content: flex-start;
  font-size: 24px;
  color: #333;
  font-family: "dingding06";
}

.news_title3 {
  display: flex;
  align-items: center;
  padding-left: 10%;
  padding-bottom: 20px;
  justify-content: flex-start;
  font-size: 24px;
  color: #333;
  font-family: "yunyingzhongxin";
}

.logo3 {
  height: 65px;
  padding: 10px;
}

.head_div {
  height: 60px;
  background-color: #0466dd25;
  width: 100vw;
  position: absolute;
  padding: 0 20px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e9e9e9;
}

.video_div {
  padding: 0;
  margin: 0;
}

#mp41 {
  object-fit: fill;
}
</style>
