import Vue from 'vue'
import VueRouter from 'vue-router'

import index_index from '../views/index/index.vue'
import about_us from '../views/about_us/index.vue'
import honor from '../views/honor/index.vue'
import case_index from '../views/case/index.vue'

Vue.use(VueRouter)

const routes = [


  {
    path: '/',
    name: 'index',
    meta: {
      title: "大湘人力资源集团",
    },
    component: index_index
  },

  {
    path: '/about_us',
    name: 'about_us',
    meta: {
      title: "关于我们",
    },
    component: about_us
  },
  {
    path: '/honor',
    name: 'honor',
    meta: {
      title: "公司荣誉",
    },
    component: honor
  },
  {
    path: '/case',
    name: 'case',
    meta: {
      title: "服务案例",
    },
    component: case_index
  }


]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title

  }
  next()
})

export default router