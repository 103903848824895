var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('Head',{ref:"head",attrs:{"menu_index":1}}),_c('div',{staticStyle:{"height":"70px"}},[_vm._v("123")]),_c('div',{staticClass:"cardlist"},[_c('div',{class:_vm.is_pc?'card':'card_h5'},[_vm._m(0),_c('div',{class:_vm.is_pc ? 'img_div' : 'img_div_h5'},[_c('div',{class:_vm.is_pc ? 'img_inner' : 'img_inner_h5'},[_vm._m(1),_vm._m(2)]),_vm._m(3)])]),_c('div',{class:_vm.is_pc?'card':'card_h5'},[_vm._m(4),_c('div',{class:_vm.is_pc ? 'img_div' : 'img_div_h5'},[_c('div',{class:_vm.is_pc ? 'img_inner' : 'img_inner_h5'},[_vm._m(5),_vm._m(6)]),_vm._m(7)])]),_c('div',{class:_vm.is_pc?'card':'card_h5'},[_vm._m(8),_c('div',{class:_vm.is_pc ? 'img_div' : 'img_div_h5'},[_c('div',{class:_vm.is_pc ? 'img_inner' : 'img_inner_h5'},[_vm._m(9),_vm._m(10)]),_vm._m(11)])]),_c('div',{class:_vm.is_pc?'card':'card_h5'},[_vm._m(12),_c('div',{class:_vm.is_pc ? 'img_div' : 'img_div_h5'},[_c('div',{class:_vm.is_pc ? 'img_inner' : 'img_inner_h5'},[_vm._m(13),_vm._m(14)]),_vm._m(15)])]),_c('div',{class:_vm.is_pc?'card':'card_h5'},[_vm._m(16),_c('div',{class:_vm.is_pc ? 'img_div' : 'img_div_h5'},[_c('div',{class:_vm.is_pc ? 'img_inner' : 'img_inner_h5'},[_vm._m(17),_vm._m(18)]),_vm._m(19)])]),_c('div',{class:_vm.is_pc?'card':'card_h5'},[_vm._m(20),_c('div',{class:_vm.is_pc ? 'img_div' : 'img_div_h5'},[_c('div',{class:_vm.is_pc ? 'img_inner' : 'img_inner_h5'},[_vm._m(21),_vm._m(22)]),_vm._m(23)])]),_c('div',{class:_vm.is_pc?'card':'card_h5'},[_vm._m(24),_c('div',{class:_vm.is_pc ? 'img_div' : 'img_div_h5'},[_c('div',{class:_vm.is_pc ? 'img_inner' : 'img_inner_h5'},[_vm._m(25),_vm._m(26)]),_vm._m(27)])]),_c('div',{class:_vm.is_pc?'card':'card_h5'},[_vm._m(28),_c('div',{class:_vm.is_pc ? 'img_div' : 'img_div_h5'},[_c('div',{class:_vm.is_pc ? 'img_inner' : 'img_inner_h5'},[_vm._m(29),_vm._m(30)]),_vm._m(31)])]),_c('Footer')],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news_title2"},[_c('div',{staticClass:"dito"}),_vm._v(" 长沙联通-服务案例 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"img3",staticStyle:{"height":"240px"},attrs:{"src":require("@/assets/img/case/liantong1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-left":"10px"}},[_c('div',[_c('img',{staticClass:"img3",staticStyle:{"height":"120px"},attrs:{"src":require("@/assets/img/case/liantong2.png"),"alt":""}})]),_c('div',[_c('img',{staticClass:"img3",staticStyle:{"height":"120px"},attrs:{"src":require("@/assets/img/case/liantong3.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img_txt"},[_c('div',{staticClass:"img_txt1"},[_vm._v(" 2019年，大湘通过竞标承接了长沙联通部分业务外包，项目接手后，时间紧、任务重，需要在最快的时间内完成长沙市各区县分公司千余人的转签工作。 ")]),_c('div',{staticClass:"img_txt1"},[_vm._v(" 我司接到任务后，火速成立项目团队，配合法务制定出了一套完善的方案，有效的控制了人员流失风险及劳动合同纠纷，在一个星期内完成了15个区县的员工转签，并用“零”事故向联通交付了第一张满意的答卷，在专业上获得了联通各级领导的高度赞许。招聘是企业引用我司，体现我司价值的根本。 ")]),_c('div',{staticClass:"img_txt1"},[_vm._v(" 10-12月，联通客户经理招聘入职任务为300人，市场营销类人员招聘一直是招聘难点，年底更难，但我司联通项目全体人员每晚工作到凌晨，终于完成了既定目标，在招聘能力上得到了联通公司上下的高度肯定。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news_title2"},[_c('div',{staticClass:"dito"}),_vm._v(" 中信戴卡-服务案例 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"img3",staticStyle:{"height":"240px","width":"300px"},attrs:{"src":require("@/assets/img/case/daika1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-left":"10px"}},[_c('div',[_c('img',{staticClass:"img3",staticStyle:{"height":"120px"},attrs:{"src":require("@/assets/img/case/daika2.png"),"alt":""}})]),_c('div',[_c('img',{staticClass:"img3",staticStyle:{"height":"120px"},attrs:{"src":require("@/assets/img/case/daika3.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img_txt"},[_c('div',{staticClass:"img_txt1"},[_vm._v(" 中信戴卡是是目前世界上最大的铝车轮生产企业，全球汽车零部件百强企业，为奔驰、宝马、奥迪、大众等主要整车配套制造商。 ")]),_c('div',{staticClass:"img_txt1"},[_vm._v(" 长沙戴卡于2016年落户望城经开区，一期计划用工量1000人，项目筹建之初企业即联系我司提供人才供应保障。该项目从基建到投产，筹建时间非常之短，且前期招聘人员均需派至山东进行为期三个月的培训，待长沙基地建成后返回。需求量大、时间紧迫、任务特殊，且企业对人才质量要求高，大湘通过不懈努力，在一个多月时间内招聘四百多人派至山东，并通过专业、细致管理，大大降低了人员异地流失率，以确保人员供给稳定。同时，在一期工厂未建成宿舍前负责解决近千名工人的宿舍安置及管理，实现了从招聘、入职、培训、现场管理、食宿后勤等全流程外包服务。 ")]),_c('div',{staticClass:"img_txt1"},[_vm._v(" 另应企业需求为其猎聘生产、工艺、设备、物流、财务、人力资源等中高级管理人才若干。专业的人、做专业的事，目前戴卡约90%的人才均为大湘招聘，大大减轻了企业生产经营过程中的用人需求和管理压力。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news_title2"},[_c('div',{staticClass:"dito"}),_vm._v(" 安吉物流-服务案例 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"img3",staticStyle:{"height":"200px","width":"250px"},attrs:{"src":require("@/assets/img/case/anji1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-left":"10px"}},[_c('div',[_c('img',{staticClass:"img3",staticStyle:{"height":"120px"},attrs:{"src":require("@/assets/img/case/anji2.png"),"alt":""}})]),_c('div',[_c('img',{staticClass:"img3",staticStyle:{"height":"120px"},attrs:{"src":require("@/assets/img/case/anji3.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img_txt"},[_c('div',{staticClass:"img_txt1"},[_vm._v(" 安吉物流是上汽集团所属专业从事汽车物流业务的全资子公司。 ")]),_c('div',{staticClass:"img_txt1"},[_vm._v(" 长沙安吉于2014年落户长沙经开区大众产业园，作为大众核心配套企业，安吉用人要求高、翻班时间特殊，导致招聘难度非常大。大湘作为安吉第一家合作的大型人力资源供应商，多年来为其提供人才供应和后勤保障，任何时侯以解决客户需求为第一要务，排除困难，不计成本，保障企业用工需求，成为客户忠实的合作伙伴，得到了客户的充分肯定和好评。 ")]),_c('div',{staticClass:"img_txt1"},[_vm._v(" 口碑是最好的广告，介于大湘高效的招聘能力和优质的服务保障，在汽车行业领域服务了大量的客户，并取得了客户的一致好评。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news_title2"},[_c('div',{staticClass:"dito"}),_vm._v(" 顺丰速运-服务案例 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"img3",staticStyle:{"height":"200px","width":"250px"},attrs:{"src":require("@/assets/img/case/shunfeng1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-left":"10px"}},[_c('div',[_c('img',{staticClass:"img3",staticStyle:{"height":"120px"},attrs:{"src":require("@/assets/img/case/shunfeng2.png"),"alt":""}})]),_c('div',[_c('img',{staticClass:"img3",staticStyle:{"height":"120px"},attrs:{"src":require("@/assets/img/case/shunfeng3.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img_txt"},[_c('div',{staticClass:"img_txt1"},[_vm._v(" 2017年起，大湘人力资源集团与湖南顺丰速运达成劳务外包工序类合作，从起初几十人的工序外包发展到至今600人的全流程工序外包，特别在618、双十一、年底大促等活动期间，大湘给力的人员输送量和输送速度，完善的管理和风控体系，多次获得企业高度好评。 ")]),_c('div',{staticClass:"img_txt1"},[_vm._v(" 大湘集团旗下“湖南大湘机电有限公司”和“湖南潇湘人力资源服务有限公司”均获得2019年度优秀合作伙伴称号。 ")]),_c('div',{staticClass:"img_txt1"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news_title2"},[_c('div',{staticClass:"dito"}),_vm._v(" 一喜、可孚-服务案例 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"img3",staticStyle:{"height":"200px","width":"250px"},attrs:{"src":require("@/assets/img/case/kefu1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-left":"10px"}},[_c('div',[_c('img',{staticClass:"img3",staticStyle:{"height":"120px"},attrs:{"src":require("@/assets/img/case/kefu3.png"),"alt":""}})]),_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img_txt"},[_c('div',{staticClass:"img_txt1"},[_vm._v(" 2020年春节，全国疫情大爆发，国内抗疫物质告急，大年初三，大湘人力接到湖南一喜科技的求助信息，为保障政府战备物资供给，企业急需300名工人投入口罩生产。尽管疫情风险极高，人力资源严重匮乏，我司仍积极响应政府号召，急企业之所急，紧急召集招聘团队和渠道力量，一周时间完成了300人的招聘任务。随着疫情发展，该项目人数达一千多人规模。 ")]),_c('div',{staticClass:"img_txt1"},[_vm._v(" 疫情期间，大湘为一喜、可孚、比亚迪等抗疫物资生产企业共输送人员近万人次，同时承接核酸试剂检测等多个岗位外包，及口罩、温枪生产等多条产线外包，得到了企业的一致好评和政府的高度肯定。 ")]),_c('div',{staticClass:"img_txt1"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news_title2"},[_c('div',{staticClass:"dito"}),_vm._v(" 三诺生物-服务案例 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"img3",staticStyle:{"height":"200px","width":"250px"},attrs:{"src":require("@/assets/img/case/sannuo1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-left":"10px"}},[_c('div',[_c('img',{staticClass:"img3",staticStyle:{"height":"120px"},attrs:{"src":require("@/assets/img/case/sannuo2.png"),"alt":""}})]),_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img_txt"},[_c('div',{staticClass:"img_txt1"},[_vm._v(" 2017年大湘人力与三诺生物在就企业中高端人才服务与基础人力输送项目达成共识，并与当年6月签署合作协议启动项目服务事宜。作为高新区重点企业，以及生物医疗行业有代表性的客户，企业正处于高速发展阶段，对于人力需求高达300人次，且各层次人才需求持续的提报，层出不穷。 ")]),_c('div',{staticClass:"img_txt1"},[_vm._v(" 就此情况，我司积极响应客户需求，接受招聘任务的同时，及时部署团队对项目进行针对的安排与计划，就不同人才的需求形成专业交付团队进行招聘的同时，在线上线下相结合的招聘模式， ")]),_c('div',{staticClass:"img_txt1"},[_vm._v(" 启动部分校园资源的供给，组织专场招聘会，在各个维度进行企业需求的宣传，保障企业招聘信息及时发布与展示在各大求职平台与校园，安排项目人员进入企业对接需求，同步每天组织项目面试工作，在持续的攻坚下，团队在企业要求的时间内提前完成了招聘需求，超预期的实现了项目交付工作，项目高峰在职人数800人，双方一直建立着深厚的多维度合作关系！ ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news_title2"},[_c('div',{staticClass:"dito"}),_vm._v(" 湘钢集团-服务案例 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"img3",staticStyle:{"height":"220px","width":"250px"},attrs:{"src":require("@/assets/img/case/xianggang1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-left":"10px"}},[_c('div',[_c('img',{staticClass:"img3",staticStyle:{"height":"100px"},attrs:{"src":require("@/assets/img/case/xianggang2.png"),"alt":""}})]),_c('div',[_c('img',{staticClass:"img3",staticStyle:{"height":"120px"},attrs:{"src":require("@/assets/img/case/xianggang3.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img_txt"},[_c('div',{staticClass:"img_txt1"},[_vm._v(" 2020年5月，大湘人力通过竞标，承接了湘钢集团旗下“湖南湘钢工程技术有限公司”人力外包项目，至此开启了与湘钢的合作之旅，至今仍保持的优秀的合作关系。项目开启之初，湖南湘钢工程技术有限公司面临着400人的人力需求，且要求在3个月之内必须保障员工到岗，同时岗位涉及行车、焊工、装配、维保等多个领域。 ")]),_c('div',{staticClass:"img_txt1"},[_vm._v(" 为了及时有效的达成企业任务，在接到招聘需求的任务之后，我司特在湘潭公司，要求区域负责人单独组建项目小组，集合总部公司的招聘团队支持，整合当地渠道合作资源，项目经理带队6人的小组成员，一起在项目开展了为期3个月的招聘与服务工作，在招聘首月即完成了项目任务的50%（210人），其后陆续在2个月之内有序的达成了企业招聘目标，并积极的落实了员工入职前后的相关手续以及培训工作，保障了人员到岗后的人效达标，且稳岗率高达89%，在当年的服务商体系得到了湘钢集团的高度赞扬，并授予了我司年度优质合作伙伴的牌匾。 ")]),_c('div',{staticClass:"img_txt1"},[_vm._v(" 合作至今，我司在职员工近500人，依然秉承的客户的需求为上，服务员工为主的经营理念，保持着优质的合作关系同时，大湘人力自合作以来连续被企业评选为2021、2022、2023年度优秀供应商！ ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news_title2"},[_c('div',{staticClass:"dito"}),_vm._v(" 中车-服务案例 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"img3",staticStyle:{"height":"220px","width":"220px"},attrs:{"src":require("@/assets/img/case/zhongche3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-left":"10px"}},[_c('div',[_c('img',{staticClass:"img3",staticStyle:{"height":"100px"},attrs:{"src":require("@/assets/img/case/zhongche2.png"),"alt":""}})]),_c('div',[_c('img',{staticClass:"img3",staticStyle:{"height":"100px"},attrs:{"src":require("@/assets/img/case/zhongche1.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img_txt"},[_c('div',{staticClass:"img_txt1"},[_vm._v(" 2022年，大湘人力通过竞标，承接了中国中车旗下“中车时代新材科技股份有限公司”部分人力资源服务的业务外包，项目中标后，我司第一时间成立专班项目小组，并参照企业方要求紧锣密鼓制定了服务方案，同年12月底启动了1000余人的劳动关系转移工作，在企业方的支持与我司服务团队的配合下，及时有序的在当月完成了人员转签与相关手续的办理工作，在服务专业与细节把控层面得到了企业高度认可。 ")]),_c('div',{staticClass:"img_txt1"},[_vm._v(" 2023年12月企业第一时间提报株洲、江苏、新疆、内蒙古等四个分厂第一季度共计1250人的招聘任务，时间紧任务重，我司为此在湖南各个地市公司抽调招聘骨干，与总部100余人的招聘团队形成合力，并同时启动100多家招聘渠道供应商的合作，就此，我司参照企业要求在2024年5月份共计完成1200人的招聘，并组织员工体检、合同签订等工作后第一时间到各地市公司到岗报到，有效的保证了企业的人力输送需求，更进一步的展示了我司在集中招聘交付层面的硬实力。 ")]),_c('div',{staticClass:"img_txt1"},[_vm._v(" 目前大湘服务中车全国交付，在职员工过千人，员工与企业对我司阶段的服务与工作完成效率表示了高度肯定与好评！ ")])])
}]

export { render, staticRenderFns }