<template>
  <div>
    <div class="head_div" v-if="is_pc">
      <div class="logo_box">
        <img @click="click_logo" src="@/assets/img/index/logo2.png" alt="" class="logo" />
        <!-- <div class="is_pc_tag">电脑版官网</div> -->
      </div>

      <div class="head_menu_div">
        <div
          :class="menu_index == i ? 'head_menu_title2' : 'head_menu_title'"
          @click="click_menu(item, i)"
          v-for="(item, i) in menu_list"
          :key="i"
        >
          {{ item.title }}
        </div>
        <!-- <div class="head_menu_title"></div>
        <div class="head_menu_title">加入我们</div> -->
      </div>
    </div>
    <div class="head_div_h5" v-else>
      <div class="logo_box">
        <img
          @click="click_logo"
          src="@/assets/img/index/logo2.png"
          alt=""
          class="logo_h5"
        />
        <div class="is_pc_tag">手机版官网</div>
      </div>

      <div class="head_div_menu" @click="click_menu_icon">
        <Icon type="md-list" size="30" color="#fff" />
      </div>
    </div>
    <Drawer
      title="菜单"
      placement="right"
      :closable="false"
      v-model="h5_menu_show"
      width="50"
      @on-close="close_change"
    >
      <div
        @click="click_menu(item, i)"
        :class="menu_index == i ? 'h5menu_link2' : 'h5menu_link'"
        v-for="(item, i) in menu_list"
        :key="i"
      >
        {{ item.title }}
      </div>
    </Drawer>
  </div>
</template>

<script>
import { open_new_page, check_pc } from "@/utils/http";
export default {
  name: "Head",
  props: ["menu_index"],
  data() {
    return {
      is_pc: true,
      menu_list: [
        { title: "首页", path: "/" },
        { title: "服务案例", path: "/case" },
        { title: "企业荣誉", path: "/honor" },
        { title: "关于我们", path: "/about_us" },
      ],
      h5_menu_show: false,
    };
  },
  mounted() {
    this.is_pc = check_pc();
  },
  methods: {
    click_menu_icon() {
      this.h5_menu_show = true;
      console.log("点击打开");
      this.$emit("show_change", "open");
    },

    click_menu(item, i) {
      console.log(item, i);
      open_new_page(item.path, "_self");
    },

    click_logo() {
      open_new_page("/");
    },

    close_change() {
      this.$emit("show_change", "close");
    },
  },
};
</script>

<style scoped>
.is_pc_tag {
  padding:5px 10px;
  border-radius: 3px;
  background: #0663d5;
  color: #eaeaea;
  font-weight: 400;
  font-size: 12px;
}

.logo_box {
  display: flex;
  align-items: center;
}

.h5menu_link2 {
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #0050b2;
}

.h5menu_link {
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
}

.head_div_menu {
  width: 60px;
  height: 40px;
  line-height: 40px;
}

.logo_h5 {
  height: 50px;
  padding: 10px;
  cursor: pointer;
}
.logo {
  height: 60px;
  padding: 10px;
  cursor: pointer;
}
.head_menu_title {
  margin-right: 40px;
  font-size: 17px;
  color: #ffffff;
  font-weight: 600;
  cursor: pointer;
  padding-bottom: 5px;
  border-bottom: 5px solid #03295500;
}

.head_menu_title2 {
  margin-right: 40px;
  font-size: 17px;
  color: #fff;
  font-weight: 600;
  padding-bottom: 5px;
  border-bottom: 4px solid #fff;
  cursor: pointer;
}

.head_menu_title:hover {
  color: #a3c9f4;
}

.head_menu_div {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 100px;
}
.news_title2 {
  display: flex;
  align-items: center;
  padding-left: 10%;
  padding-bottom: 20px;
  justify-content: flex-start;
  font-size: 24px;
  color: #333;
  font-family: "dingding03";
}

.logo3 {
  height: 65px;
  padding: 10px;
}

.head_div {
  height: 60px;
  background-color: #0050b2;
  width: 100vw;
  position: absolute;
  z-index: 999;
  padding: 0 20px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.head_div_h5 {
  height: 60px;
  background-color: #0050b2;
  width: 100vw;
  position: absolute;
  z-index: 999;
  padding: 0 10px;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
