import axios from "axios"

function request(type, url, data) {


  let ajaxConfig = {
    method: type,
    url: url,
    data: data,
    proxy: true,
    headers: {
      'Content-Type': 'application/json'
    }

  }
  // console.log(ajaxConfig)
  return axios(ajaxConfig)
}


function open_new_page(url, target) {
  if (target) {
    var t = target
  } else {
    var t = '_blank'
  }

  let a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("target", t);
  var id = String(new Date().getTime())
  a.setAttribute("id", id);
  // 防止反复添加      
  document.body.appendChild(a);
  a.click();

}
function check_pc() {
  if (/(micromessenger)/i.test(navigator.userAgent)) {
    return false;
  } else {
    const sUserAgent = navigator.userAgent.toLowerCase()
    /* eslint-disable */
    const bIsIpad = sUserAgent.match(/ipad/i) == 'ipad'
    const bIsIphoneOs = sUserAgent.match(/iphone os/i) == 'iphone os'
    const bIsMidp = sUserAgent.match(/midp/i) == 'midp'
    const bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == 'rv:1.2.3.4'
    const bIsUc = sUserAgent.match(/ucweb/i) == 'ucweb'
    const bIsAndroid = sUserAgent.match(/android/i) == 'android'
    const bIsCE = sUserAgent.match(/windows ce/i) == 'windows ce'
    const bIsWM = sUserAgent.match(/windows mobile/i) == 'windows mobile'

    if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
      console.log('h5')
      return false;
    } else {
      console.log('pc')
      return true;
    }
  }



}


export {
  request,
  open_new_page,
  check_pc

}